import { FC, ReactElement } from 'react'
import { css } from '@emotion/react'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

const styles = {
  mainTxt: css({
    fontWeight: 'bold',
    display: 'block',
    lineHeight: 1.4
  }),
  actionIcon: css({
    position: 'absolute',
    top: 0,
    bottom: 0,
    margin: 'auto',
    right: '5%'
  }),
  symbolIcon: css({
    paddingRight: 12
  })
}

export interface ButtonInnerProps {
  mainText: ReactElement
  subText?: ReactElement
  actionIcon?: boolean
  symbolIcon?: ReactElement
}

export const ButtonInner: FC<ButtonInnerProps> = (props) => {
  const { symbolIcon, subText, mainText, actionIcon = true } = props
  return (
    <>
      {symbolIcon && <span css={styles.symbolIcon}>{symbolIcon}</span>}
      <span>
        {subText}
        <span css={styles.mainTxt}>{mainText}</span>
      </span>
      {actionIcon && (
        <ChevronRightIcon fontSize="small" css={styles.actionIcon} />
      )}
    </>
  )
}
