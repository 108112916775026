import { FC } from 'react'
import { ButtonInner, ButtonInnerProps } from './ButtonInner'
import { ButtonWrapper, ButtonWrapperProps } from './ButtonWrapper'

export type ButtonProps = ButtonInnerProps & ButtonWrapperProps

export const Button: FC<ButtonProps> = (props) => {
  const { mainText, subText, actionIcon, symbolIcon, ...rest } = props
  return (
    <ButtonWrapper {...rest}>
      <ButtonInner
        mainText={mainText}
        subText={subText}
        actionIcon={actionIcon}
        symbolIcon={symbolIcon}
      />
    </ButtonWrapper>
  )
}
