import {
  FC,
  ButtonHTMLAttributes,
  AnchorHTMLAttributes,
  HTMLAttributes
} from 'react'
import { css } from '@emotion/react'
import { MainAndAccent, palette } from '@lifedot/styles/palette'
import { transition } from '@lifedot/styles/transition'

const styles = {
  button: (large: ButtonWrapperPropsCommon['large']) =>
    css({
      border: '0',
      height: large ? 96 : 52,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      width: '100%',
      padding: '2px 8px',
      borderRadius: 4,
      transition: transition('easeOut'),
      transform: 'translateY(-4px)',
      cursor: 'pointer',
      textDecoration: 'none',
      textAlign: 'center',
      '&:active': {
        boxShadow: 'none',
        transform: 'translateY(0)'
      }
    }),
  disabled: css({
    color: palette.gray6,
    borderColor: palette.gray3,
    backgroundColor: palette.gray3,
    boxShadow: `0 4px 0 ${palette.gray4}`,
    '&:active': {
      transform: 'translateY(-4px)',
      boxShadow: `0 4px 0 ${palette.gray4}`
    }
  })
}

const stylePrimary = {
  main: css({
    color: palette.black,
    backgroundColor: palette.main,
    boxShadow: `0 4px 0 ${palette.main02}`
  }),
  accent: css({
    color: palette.white,
    backgroundColor: palette.accent,
    boxShadow: `0 4px 0 ${palette.accent02}`
  })
}

const styleSecondary = {
  main: css({
    color: palette.main03,
    border: `1px solid ${palette.main}`,
    backgroundColor: palette.white,
    boxShadow: `0 4px 0 ${palette.main}`
  }),
  accent: css({
    color: palette.accent02,
    border: `1px solid ${palette.accent02}`,
    backgroundColor: palette.white,
    boxShadow: `0 4px 0 ${palette.accent02}`
  })
}

type AsElementType<T extends 'a' | 'div' | 'button'> = {
  as: T
}

interface ButtonWrapperPropsCommon {
  color?: MainAndAccent
  variant?: 'primary' | 'secondary'
  large?: boolean
}

type ButtonWrapperPropsButton =
  | (ButtonHTMLAttributes<HTMLButtonElement> &
      AsElementType<'button'> &
      ButtonWrapperPropsCommon)
  | (ButtonHTMLAttributes<HTMLButtonElement> & ButtonWrapperPropsCommon)
type ButtonWrapperPropsAnchor = AnchorHTMLAttributes<HTMLAnchorElement> &
  AsElementType<'a'> &
  ButtonWrapperPropsCommon
type ButtonWrapperPropsDiv = HTMLAttributes<HTMLDivElement> &
  AsElementType<'div'> &
  ButtonWrapperPropsCommon

export type ButtonWrapperProps =
  | ButtonWrapperPropsDiv
  | ButtonWrapperPropsAnchor
  | ButtonWrapperPropsButton

const isButton = (arg: ButtonWrapperProps): arg is ButtonWrapperPropsButton =>
  !('as' in arg) || arg.as === 'button'
const isAnchor = (arg: ButtonWrapperProps): arg is ButtonWrapperPropsAnchor =>
  'as' in arg && arg.as === 'a'
const isDiv = (arg: ButtonWrapperProps): arg is ButtonWrapperPropsDiv =>
  'as' in arg && arg.as === 'div'

export const ButtonWrapper: FC<ButtonWrapperProps> = (props) => {
  const { color = 'main', variant = 'primary', large = false, ...rest } = props
  const styleSupplement =
    variant === 'primary' ? stylePrimary[color] : styleSecondary[color]
  if (isButton(rest))
    return (
      <button
        {...rest}
        css={[
          styles.button(large),
          rest.disabled ? styles.disabled : styleSupplement
        ]}
      />
    )
  if (isAnchor(rest))
    return <a {...rest} css={[styles.button(large), styleSupplement]} />
  if (isDiv(rest))
    return (
      <div
        {...rest}
        css={[styles.button(large), styleSupplement]}
        role="button"
      />
    )
  return <></>
}
