import { Children, FC, ReactNode, useMemo } from 'react'
import { css } from '@emotion/react'

const styles = {
  block: (v: AddMarginWrapperProps['spacing']) =>
    css({
      ':not(:first-of-type)': {
        marginTop: v * 4
      }
    })
}

interface AddMarginWrapperProps {
  children: ReactNode
  spacing: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10
}

export const AddMarginWrapper: FC<AddMarginWrapperProps> = ({
  children,
  spacing
}) => {
  const items = useMemo(() => Children.toArray(children), [children])
  return (
    <div>
      {items.map((item, index) => (
        <StyledDiv spacing={spacing} key={index}>
          {item}
        </StyledDiv>
      ))}
    </div>
  )
}

const StyledDiv: FC<AddMarginWrapperProps> = ({ spacing, children }) => {
  return <div css={styles.block(spacing)}>{children}</div>
}
