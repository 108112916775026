import { css } from '@emotion/react'
import { FC } from 'react'
import { typography } from '@lifedot/styles/typography'
import { Button } from '@lifedot/atoms/Button'
import { mq } from '@lifedot/styles/mediaQuery'
import { palette } from '@lifedot/styles/palette'
import { AddMarginWrapper } from '../AddMarginWrapper'
import Image from 'next/image'
import Link from 'next/link'

const styles = {
  wrap: css({
    padding: 40,
    backgroundColor: palette.white,
    [mq('sp')]: {
      padding: 32
    }
  }),
  inner: css({
    width: '70%',
    margin: 'auto',
    [mq('sp')]: {
      width: '100%'
    }
  }),
  link: css({
    display: 'block',
    width: '100%',
    maxWidth: 400,
    margin: 'auto',
    textDecoration: 'none'
  }),
  img: css({
    width: 200,
    margin: 'auto',
    [mq('sp')]: {
      width: 160
    }
  })
}

export const NotFound: FC = () => {
  return (
    <div css={styles.wrap}>
      <div css={styles.inner}>
        <AddMarginWrapper spacing={4}>
          <h2 css={typography.headingXS}>お探しのページが見つかりません</h2>
          <p css={typography.textM}>
            当サイトをご覧いただきありがとうございます。
            <br />
            大変申し訳ないのですが、あなたがアクセスしようとしたページは削除されたかURLが変更されています。
            <br />
            お手数をおかけしますが、ライフドットトップページからもう一度目的のページをお探しください。
          </p>
          <figure css={styles.img}>
            <Image
              src="https://s3.lifedot.jp/uploads/ending/path/56739/image_404.png"
              width={264}
              height={348}
              alt="お辞儀をする女性"
            />
          </figure>
          <Link href="/" passHref prefetch={false}>
            <a css={styles.link}>
              <Button mainText={<>TOPへ戻る</>} as="div" />
            </a>
          </Link>
        </AddMarginWrapper>
      </div>
    </div>
  )
}
